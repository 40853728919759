(function($) {

    $(window).on('scroll',function(){
        let sticky = $('.navigation'),
        scroll = $(window).scrollTop();

        if (scroll >= 30) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
    });


})(jQuery);






