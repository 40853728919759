var headerSwiper = new Swiper(".headerSwiper", {
    slidesPerView: 1,
    direction: 'horizontal',
    fadeEffect: { crossFade: true },
    virtualTranslate: true,
    autoplay: {
        delay: 3500,
        disableOnInteraction: true
    },
    speed: 2000,
    slidersPerView: 1,
    effect: 'fade',
    breakpoints:{
      320:{
          slidesPerView:1
      }
  },
  });


  var servicesSwiper = new Swiper(".servicesSwiper", {
    slidesPerView: 1,
    direction: 'horizontal',
    
    spaceBetween: 24,

    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation:{
        prevEl:'.swiper-prev',
        nextEl:'.swiper-next'
    },
    autoplay: {
      delay: 1000,
      disableOnInteraction: true
  },
  speed: 1000,
  autoplay:false,
  loop:true,
    breakpoints:{
      768:{
          slidesPerView:2,
      },
      1224:{
          slidesPerView:3,
      }
    },
  });


var beforeSwiper = new Swiper(".beforeSwiper", {
    slidesPerView: 1,
    direction: 'horizontal',
    autoplay:false,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints:{
      320:{
          slidesPerView:1
      }
  },
  });