(function($) {
     let header = $('#js-header')
     let menu = $('#js-header-menu')
    

    $('#js-burger').on('click', function () {
        $(this).toggleClass('is-active');
        header.toggleClass('is-active')
        menu.toggleClass('is-active')
    });

    

})(window.jQuery)

  const $menu = $('.menu');
  const $menu_2= $('.header-menu');
  const $close = $('.header__burger');
  const $header = $('.header');

$(document).mouseup(e => {
   if (!$menu.is(e.target) 
   && $menu.has(e.target).length === 0
   && $('#js-burger').has(e.target).length === 0
   && !$('#js-burger').is(e.target) )
   {
     $menu_2.removeClass('is-active');
     $close.removeClass('is-active');
     $header.removeClass('is-active');
  }
  

 });

