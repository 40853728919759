import AOS from "aos";

(function ($) {
 
      
    const {
        fromEvent
    } = require("rxjs");


    var documentReady = function () {
        console.log("DOM Loaded");
        require("./swiper");
        require("./hamburger");
        require("./scroll");
        require("./faq");
        require("./before");


        // RxJS working
        fromEvent(document, "click").subscribe(() => {
            // test();
        });
        
    };

    if (
        document.readyState === "complete" ||
        (document.readyState !== "loading" && !document.documentElement.doScroll)
    ) {
        documentReady();
    } else {
        document.addEventListener("DOMContentLoaded", documentReady);
    }


    AOS.init();


})(jQuery);











